<template>
  <div class="pageContainer">
    <h2 class="w-100 text-center graph-title">Position Data Graph</h2>
    <div class="chartContainerStyle">
      <DataChart :chart-data="accXChartData" :options="accXChartOptions" :styles="chartStyle"/>
    </div>
    <div class="chartContainerStyle">
      <DataChart :chart-data="accYChartData" :options="accYChartOptions" :styles="chartStyle"/>
    </div>
    <div class="chartContainerStyle">
      <DataChart :chart-data="accZChartData" :options="accZChartOptions" :styles="chartStyle"/>
    </div>
  </div>
</template>

<script>
import DataChart from '../components/DataChart.js'
import { AdjustingInterval } from '../assets/js/AdjustingInterval.js'

export default {
  name: 'DataGraphPosition',
  components: {
    DataChart,
  },

  data() {
    return {
      chartStyle: {
        height: '100%',
        width: '95%'
      },

      accXChartOptions: {
        animation: false,
        title: {
          display: true,
          text: "X-Achse [g]",
          fontColor: '#2c3e50',
          fontSize: "14"
        },
        events: [],
        legend: {
          display: true,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: -1,
              suggestedMax: 1
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      accYChartOptions: {
        animation: false,
        title: {
          display: true,
          text: "Y-Achse [g]",
          fontColor: '#2c3e50',
          fontSize: "14"
        },        
        events: [],
        legend: {
          display: true,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: -1,
              suggestedMax: 1
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      accZChartOptions: {
        animation: false,
        title: {
          display: true,
          text: "Z-Achse [g]",
          fontColor: '#2c3e50',
          fontSize: "14"
        },        
        events: [],
        legend: {
          display: true,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: -1,
              suggestedMax: 1
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },            

      accXChartData: null,
      accYChartData: null,
      accZChartData: null,

      timer: null
    }
  },

  async mounted() {
    if(this.$store.state.sMotionAvailable) {
      await this.$store.state.motHandles.acc.startNotifications()

      //this.timer = setInterval(this.loadData, 40)
      this.timer = new AdjustingInterval(this.loadData, 50, this.onError)
      this.timer.start()
    }
  },

  methods: {
    loadData() {
      let labels = []
      let xData = this.$store.state.motDataAcc.x
      let yData = this.$store.state.motDataAcc.y
      let zData = this.$store.state.motDataAcc.z

      for(let i = 0; i < 50; i++) {
        labels.push(i+1)
      }

      this.accXChartData = {
        labels: labels,
        datasets: [{
          label: 'Beschleunigung X in g',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: xData
        }]
      }

      this.accYChartData = {
        labels: labels,
        datasets: [{
          label: 'Beschleunigung Y in g',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: yData
        }]
      }

      this.accZChartData = {
        labels: labels,
        datasets: [{
          label: 'Beschleunigung Z in g',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: zData
        }]
      }            
    },

    onError() {
      console.warn('The drift exceeded the interval!');
    },

    cancelUpdates() {
      //clearInterval(this.timer)
      this.timer.stop()
    }
  },

  async beforeDestroy() {
    if(this.$store.state.bleConnected) {
      this.cancelUpdates()
      await this.$store.state.motHandles.acc.stopNotifications()
    }
  }
}
</script>

<style>
.pageContainer {
  width: 100%;
  height: 100%;
  font-size: 0px;
}
.graph-title {
  font-size: 18px;
}
.chartContainerStyle {
  display: inline-block;
  height:  31%;
  width: 100%;
  padding-bottom: 10px;
}
</style>